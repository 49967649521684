/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';

// import { ReactComponent as Email } from '../../images/icons/newsletter-settings.svg';
// import { ReactComponent as Checkmark } from '../../images/icons/checkmark.svg';

import { ReactComponent as ArrowDownIcon } from '../../images/icons/arrowDown.svg';
// import Button, { ICON_RIGHT } from '../Button/Button';
import useMediaQuery from '../../utils/useMediaQuery';

import styles from './NormalFooterSection.module.scss';
import { getPagePath } from '../../utils/appRoutes';
import {
  ARD_ALL_CATEGORIES,
  ARD_ALL_SHOPS,
  ARD_BUSINESS,
  ARD_PRIVACY,
  ARD_PROFILE_BOOKMARKS,
  ARD_PROFILE_NEWSLETTER,
  ARD_PROFILE_PERSONAL_INFO,
  ARD_PROFILE_RECENT_PRODUCTS,
  ARD_PROFILE_WISHLIST,
} from '../../utils/appRoutes.definitions';
import { BOOKMARKS, CART } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import CustomerServiceLetter from '../CustomerServiceLetter/CustomerServiceLetter';

const LoginModal = loadable(() => import('../LoginModal/LoginModal'));
const InstallPWAButton = loadable(() => import('../InstallPWAButton'));

const NormalFooterSection = ({ config, profile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isGreaterOrEqualTo, BREAKPOINTS, isLowerThan } = useMediaQuery();

  // HardCoded top 5 categories for our domains (can create an api for it at some point)
  //todo: this is very bad approach :)
  const topFiveNl = [
    { name: 'Klussen', slug: 'klussen' },
    { name: 'Woonaccessoires', slug: 'woonaccessoires' },
    { name: 'MultiMedia', slug: 'multimedia' },
    { name: 'Kleding', slug: 'kleding' },
    { name: 'Cadeaus & gadgets', slug: 'cadeaus-gadgets' },
  ];

  const topFiveAe = [
    { name: 'Clothing', slug: 'clothing' },
    { name: 'Shoes', slug: 'shoes' },
    { name: 'Fashion Accessories', slug: 'fashion-accessories' },
    { name: 'Health and Beauty', slug: 'health-and-beauty' },
    { name: 'Books', slug: 'books' },
  ];

  const topFive = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE === 'nl' ? topFiveNl : topFiveAe;

  return (
    <>
      <section className={styles.newsletterSection}>
        <div className="container">
          <div className="row">
            <div className={classnames(styles.left, 'col-3-l')}>
              <img
                width="150"
                src={config.theme.logos.main_logo_inverted || config.theme.logos.main_logo}
                alt="Webshop footer logo"
              />
              <p>{config.footer?.webshop?.line1}</p>
              <p>{config.footer?.webshop?.line2}</p>
              <InstallPWAButton />
            </div>
            <div className={classnames(styles.middle, 'col-12-xs', 'col-5-l')}>
              {isLowerThan(BREAKPOINTS.LARGE) && (
                <div className={styles.image}>
                  <img
                    width="150"
                    src={config.theme.logos.main_logo_inverted || config.theme.logos.main_logo}
                    alt="Webshop footer logo"
                  />
                  <p>
                    <FormattedMessage
                      id="footer.newsletterText"
                      defaultMessage="Search through all the webshops of AE."
                    />
                  </p>
                  <p>
                    <FormattedMessage id="footer.newsletterTextNext" defaultMessage="Online shopping starts here!" />
                  </p>
                  <InstallPWAButton />
                </div>
              )}
              {profile && profile.newsletterFrequency !== 'none' && (
                <div className={styles.listContainer}>
                  <div className={styles.categoryList}>
                    {isLowerThan(BREAKPOINTS.LARGE) && (
                      <>
                        <input hidden type="checkbox" aria-label="More about webshop toggle" />
                        <div className={styles.caption}>
                          <FormattedMessage id="footer.categoryList" defaultMessage="Top categories" />
                          <span>
                            <ArrowDownIcon />
                          </span>
                        </div>
                      </>
                    )}
                    {isGreaterOrEqualTo(BREAKPOINTS.LARGE) && (
                      <div className={styles.caption}>
                        <FormattedMessage id="footer.categoryList" defaultMessage="Top categories" />
                      </div>
                    )}
                    <ul>
                      {topFive.map((category) => {
                        return (
                          <li key={`top_five_${category.slug}`}>
                            <Link to={`/${category.slug}/`}>{category.name}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className={styles.accountList}>
                    {isLowerThan(BREAKPOINTS.LARGE) && (
                      <>
                        <input hidden type="checkbox" aria-label="More about webshop toggle" />
                        <div className={styles.caption}>
                          <FormattedMessage
                            id="footer.accountList"
                            defaultMessage="Your account"
                            values={{ webShopName: config.websiteName }}
                          />
                          <span>
                            <ArrowDownIcon />
                          </span>
                        </div>
                      </>
                    )}
                    {isGreaterOrEqualTo(BREAKPOINTS.LARGE) && (
                      <div className={styles.caption}>
                        <FormattedMessage
                          id="footer.accountList"
                          defaultMessage="Your account"
                          values={{ webShopName: config.websiteName }}
                        />
                      </div>
                    )}
                    <ul>
                      <li>
                        <Link to={getPagePath(ARD_PROFILE_PERSONAL_INFO)}>
                          <FormattedMessage id="profile.personalInfo" defaultMessage="Personal information" />
                        </Link>
                      </li>
                      <li>
                        <Link to={getPagePath(ARD_PROFILE_NEWSLETTER)}>
                          <FormattedMessage id="profile.newsletterSection" defaultMessage="Newsletter settings" />
                        </Link>
                      </li>
                      <li>
                        <Link to={getPagePath(ARD_PROFILE_WISHLIST)} className={styles.myWishlist}>
                          <FormattedMessage id="profile.wishlist" defaultMessage="Wishlist" />
                        </Link>
                      </li>
                      <SwitchableFeature feature={BOOKMARKS}>
                        <li>
                          <Link to={getPagePath(ARD_PROFILE_BOOKMARKS)} className={styles.myBookmarks}>
                            <FormattedMessage id="profile.bookmark" defaultMessage="Bookmarks" />
                          </Link>
                        </li>
                      </SwitchableFeature>
                      <li>
                        <Link to={getPagePath(ARD_PROFILE_RECENT_PRODUCTS)} className={styles.myRecentlyViewed}>
                          <FormattedMessage id="profile.recentProducts" defaultMessage="Recent products" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {/* {(!profile || (profile && profile.newsletterFrequency === 'none')) && (
                <>
                  <div className={classnames(styles.newsletterTitle, 'col-12-xs')}>
                    <FormattedMessage id="newsletter.header" defaultMessage="Sign up for the newsletter" />
                  </div>
                  <p className={classnames(styles.newsletterTagLine, 'col-12-xs')}>
                    <FormattedMessage
                      id="newsletter.suggestions"
                      values={{ webShopName: config.websiteName }}
                      defaultMessage="Yes, keep me up to date about the newest prodcuts and offers on Webshop and sign me up for the weekly newsletter"
                    />
                  </p>
                  <ul className={classnames(styles.newsletterUsps, 'col-12-xs')}>
                    <li>
                      <Checkmark />
                      <FormattedMessage id="popups.newsletter.usp1" defaultMessage="Inspiration and product updates" />
                    </li>
                    <li>
                      <Checkmark />
                      <FormattedMessage id="popups.newsletter.usp2" defaultMessage="Exclusive deals and discounts" />
                    </li>
                    <li>
                      <Checkmark />
                      <FormattedMessage id="popups.newsletter.usp3" defaultMessage="Promotions and sale updates" />
                    </li>
                  </ul>
                  <Button
                    classList={{
                      root: classnames(styles.newsletterButton, 'col-12-xs col-6-l'),
                    }}
                    icon={<Email />}
                    iconPosition={ICON_RIGHT}
                    variation="white"
                    onClick={() => newsletterLink()}
                  >
                    <FormattedMessage
                      id="registrationForm.newsletterButtonOne"
                      defaultMessage="Sign me up for the newsletter"
                    />
                  </Button>
                </>
              )} */}
              <div className={classnames(styles.footerLinks, 'col-12-xs')}>
                {isLowerThan(BREAKPOINTS.LARGE) && (
                  <>
                    <input hidden type="checkbox" aria-label="More about webshop toggle" />
                    <div className={styles.caption}>
                      <FormattedHTMLMessage
                        id="footer.aboutFooter"
                        defaultMessage="about"
                        values={{ webShopName: config.websiteName, webShopLink: '/' }}
                      />
                      <span>
                        <ArrowDownIcon />
                      </span>
                    </div>
                  </>
                )}
                <ul>
                  <li>
                    <FormattedMessage id="footer.privacy" defaultMessage="Privacy">
                      {(text) => <Link to={getPagePath(ARD_PRIVACY)}>{text}</Link>}
                    </FormattedMessage>
                  </li>
                  {process.env.REACT_APP_NAME !== 'shopblog-nl' && (
                    <li>
                      <FormattedMessage id="footer.business" defaultMessage="Advertising">
                        {(text) => <Link to={getPagePath(ARD_BUSINESS)}>{text}</Link>}
                      </FormattedMessage>
                    </li>
                  )}
                  <li>
                    <FormattedMessage id="footer.webshops" defaultMessage="Webshops">
                      {(text) => <Link to={getPagePath(ARD_ALL_SHOPS)}>{text}</Link>}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage id="footer.allCategories" defaultMessage="All categories">
                      {(text) => <Link to={getPagePath(ARD_ALL_CATEGORIES)}>{text}</Link>}
                    </FormattedMessage>
                  </li>
                </ul>
              </div>
            </div>
            <div className={classnames(styles.right, 'col-2-l')}>
              <div className={styles.caption}>
                <FormattedHTMLMessage
                  id="footer.aboutFooter"
                  defaultMessage="about"
                  values={{ webShopName: config.websiteName, webShopLink: '/' }}
                />
              </div>
              <ul>
                <li>
                  <FormattedMessage id="footer.privacy" defaultMessage="Privacy">
                    {(text) => <Link to={getPagePath(ARD_PRIVACY)}>{text}</Link>}
                  </FormattedMessage>
                </li>
                {/* todo: remove it when there will be content for this page */}
                {process.env.REACT_APP_NAME !== 'shopblog-nl' && (
                  <li>
                    <FormattedMessage id="footer.business" defaultMessage="Advertising">
                      {(text) => <Link to={getPagePath(ARD_BUSINESS)}>{text}</Link>}
                    </FormattedMessage>
                  </li>
                )}
                <li>
                  <FormattedMessage id="footer.webshops" defaultMessage="Webshops">
                    {(text) => <Link to={getPagePath(ARD_ALL_SHOPS)}>{text}</Link>}
                  </FormattedMessage>
                </li>
                <li>
                  <FormattedMessage id="footer.allCategories" defaultMessage="All categories">
                    {(text) => <Link to={getPagePath(ARD_ALL_CATEGORIES)}>{text}</Link>}
                  </FormattedMessage>
                </li>
              </ul>
            </div>
            <SwitchableFeature feature={CART}>
              <div className={classnames('col-12-xs col-2-l')}>
                <CustomerServiceLetter />
              </div>
            </SwitchableFeature>
          </div>
        </div>
      </section>
      {isOpen && <LoginModal isOpen={isOpen} setOpen={setIsOpen} newsletter />}
    </>
  );
};
const mapStateToProps = ({ config, intl, profile }) => ({ config, intl, profile });

NormalFooterSection.displayName = 'NormalFooterSection';
export default connect(mapStateToProps)(NormalFooterSection);
